<template>
  <div class="account-view">
    <div class="account-view__wrapper">
      <Section limiter>
        <BreadCrumbs :params="{navigation, color: 'rgba(0, 0, 0, .5)'}"/>
      </Section>
      <Section limiter>
        <Row justify="between">
          <AccountMenu v-if="$laptop"
                       class="grid-md-4 grid-lg-4"/>
          <AccountContent class="grid-sm-full grid-md-8 grid-lg-8"
                          :params="{transparent: true}">
            <Row class="account-content__agencies-header m-b-40"
                 wrap
                 justify="between">
              <h5 class="account-content__agencies-title">{{ $t('organizations.my_org') }}</h5>
              <a class="button button__main"
                 :href="addOrganizationOnKeycloak">
                {{ $t('organizations.add_org') }}</a>
            </Row>
            <Loader v-if="!ready"
                    :params="{style: {height: '355px', margin: '30px 0 0'}}"/>
            <Row v-else
                 class="-m-h-15"
                 justify="between"
                 wrap>
              <AgencyCardEditor :key="item.id"
                                :params="item"
                                v-for="item in $my.agencies"/>
            </Row>
          </AccountContent>
        </Row>
      </Section>
<ModifiedBillboard/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountAgenciesView',
  data() {
    return {
      navigation: [{
        href: '/',
        title: this.$i18n.t('menu.main'),
      }, {
        href: '/account',
        title: this.$i18n.t('menu.profile'),
      }],
      ready: false,
      addOrganizationOnKeycloak:
          `${process.env.VUE_APP_DEFAULTH_ROOT_ACCOUNT_ON_KEYCLOAK}/account/restxq/organization-add`,
    };
  },
  created() {
    this.$http.get(`/api/agencies?user=current&count=100500&lang=${this.$i18n.locale}`)
    // eslint-disable-next-line no-return-assign
      .then((resolve) => {
        this.$store.commit('SET_MY_AGENCIES', [...resolve.data.data]);
        this.ready = true;
      });
  },
};
</script>
